import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";

//
import Iconify from "./Iconify";
import useLocales from "../hooks/useLocales";
import useAuth from "../hooks/useAuth";
import { PERMISSIONS } from "../config";
import { useData } from "../contexts/DataContext";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

export function NavItem({ item, active }) {
  const theme = useTheme();
  const { translate } = useLocales();
  const { pathname } = useLocation();
  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;
  const titleTrans = translate(title);

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: theme.palette.text.black,
    fontWeight: "bold",
    // bgcolor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  };

  const activeSubStyle = {
    color: theme.palette.grey[0],
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            "&:hover": {
              backgroundColor: theme.palette.primary.main, // Ensure it stays blue on hover
            },
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={titleTrans} />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, subicon } = item;

              const isActiveSub = active(path, true);

              const isActiveRootSub = () => {
                if (pathname.includes("detail")) {
                  const pathSegment = pathname.split("/");
                  const rootPath = pathSegment
                    .slice(0, pathSegment.length - 1)
                    .join("/");
                  return rootPath === path;
                }
              };

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...((isActiveSub || isActiveRootSub()) && activeSubStyle),
                    paddingLeft: "36px",
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                    {subicon}
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <>
      <ListItemStyle
        component={RouterLink}
        to={path}
        style={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={titleTrans} />
        {info && info}
      </ListItemStyle>
    </>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const currentPermissions = useMemo(() => {
    return user?.permissions?.map((item) => item.uuid) || [];
  }, [user?.permissions]);

  const [nav, setNav] = useState([]);
  const { userData } = useData();

  const match = (path, isEnd = false) => {
    return path ? !!matchPath({ path, end: isEnd }, pathname) : false;
  };

  useEffect(() => {
    const filterNav = navConfig.filter((item) => {
      return PERMISSIONS[item.title]
        ? currentPermissions.includes(PERMISSIONS[item.title])
        : true;
    });

    let roles = userData?.user_roles;
    let roleFilterNav = filterNav.filter((val) => {
      if (roles && Array.isArray(roles)) {
        if (roles.includes("mps_c2c_admin")) {
          return val;
        } else if (roles.includes("mps_c2c_client_admin")) {
          return val.title === "Client Management";
        } else if (
          roles.includes("mps_c2c_estimation_admin") ||
          roles.includes("mps_c2c_estimation_view")
        ) {
          return val.title === "Estimation";
        } else if (
          roles.includes("mps_c2c_pricing_admin") ||
          roles.includes("mps_c2c_pricing_view")
        ) {
          return val.title === "Estimation";
        } else if (
          roles.includes("mps_c2c_contract_admin") ||
          roles.includes("mps_c2c_contract_view")
        ) {
          return val.title === "SOWContract";
        } else if (
          roles.includes("mps_c2c_milestone_admin") ||
          roles.includes("mps_c2c_milestone_view")
        ) {
          return val.title === "SOWContract";
        } else if (
          roles.includes("mps_c2c_purchase_order_admin") ||
          roles.includes("mps_c2c_purchase_order_view")
        ) {
          return val.title === "SOWContract";
        } else {
          return null;
        }
      } else {
        return null; // Handle the case where roles is undefined or not an array
      }
    });
    let childRole = roleFilterNav[0]?.children || roleFilterNav;
    if (childRole) {
      const filterChildNav = childRole?.filter((val) => {
        if (roles.includes("mps_c2c_admin")) {
          return val;
        } else if (
          roles.includes("mps_c2c_estimation_admin") ||
          roles.includes("mps_c2c_estimation_view")
        ) {
          return val.title === "Effort Estimation";
        } else if (
          roles.includes("mps_c2c_pricing_admin") ||
          roles.includes("mps_c2c_pricing_view")
        ) {
          return val.title === "Pricing";
        } else if (
          roles.includes("mps_c2c_contract_admin") ||
          roles.includes("mps_c2c_contract_view")
        ) {
          return val.title === "Contracts";
        } else if (
          roles.includes("mps_c2c_milestone_admin") ||
          roles.includes("mps_c2c_milestone_view")
        ) {
          return val.title === "Milestones";
        } else if (
          roles.includes("mps_c2c_purchase_order_admin") ||
          roles.includes("mps_c2c_purchase_order_view")
        ) {
          return val.title === "Purchase Orders";
        } else {
          return val;
        }
      });
      setNav(filterChildNav);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPermissions]);

  return (
    <Box {...other}>
      <List disablePadding sx={{ px: 2 }}>
        {nav.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
