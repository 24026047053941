export const PATH_PAGE = {
  client: {
    root: getClientPath(),
    detail: getClientPath("/detail"),
  },
  estimation: {
    root: getEstimationPath(),
    detail: getEstimationPath("/detail"),
    pricing: getEstimationPath("/pricing"),
    pricingDetail: getEstimationPath("/pricing/pricing-detail"),
  },
  contracts: {
    root: getContractPath(),
    detail: getContractPath("/detail"),
    milestones: getContractPath("/milestone"),
    milestoneDetail: getContractPath("/milestone/milestone-detail"),
    purchaseOrder: getContractPath("/purchase-order"),
    purchaseOrderDetail: getContractPath(
      "/purchase-order/purchase-order-detail"
    ),
  },
  allocation: {
    root: getallocationPath(),
    detail:getallocationPath("/detail"),
  },
  invoicing:{
    root:getInvoicingPath(),
  },
  resourcesManagement:{
    root:getResourcesManagementPath(),
    detail:getResourcesManagementPath("/resources/details"),
    timesheet:getResourcesManagementPath("/timesheet"),
    timesheetOverview:getResourcesManagementPath("/timesheet/overview"),
  },
  dashboardPage:{
    root:getDashBoardPath(),
  },
  dashboard: "/dashboard",
  login: "/login",
  page404: "/404",
  pageRoles: "/roles",
  pageSettings: "/settings",
  pageInvoicing: getClientPath("/invoicing"),
  permissionDenied: "/permissionDenied",
};

function getClientPath(path) {
  return "/client" + (path ?? "");
}

function getEstimationPath(path) {
  return getClientPath() + "/estimation" + (path ?? "");
}

function getContractPath(path) {
  return getClientPath() + "/contracts" + (path ?? "");
}

function getallocationPath(path) {
  return getClientPath() + "/allocations" + (path ?? "");
}

function getResourcesManagementPath(path){
  return "/resources"+(path ??"");
}
function getInvoicingPath(path){
  return getClientPath() +"/invoices"+(path ??"");
}
function getDashBoardPath(path){
  return "/dashboardpage"+(path ??"");
}